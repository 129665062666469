<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>添加信息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="文章名称">
                    <el-input v-model="form.title" style="width:400px;"></el-input>
                </el-form-item>
                <el-form-item label="分类">
                    <el-select v-model="form.classId" placeholder="请选择分类">
                    <el-option v-for="classitem in classlist" :label="classitem.className" :value="classitem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地区" prop="holdField" v-show="userInfo.orgId==0">
                    <el-select v-model="form.orgId" placeholder="请选地区">
                        <el-option
                        v-for="item in orglist"
                        :key="item.orgId"
                        :label="item.districtName"
                        :value="item.orgId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源">
                     <el-input v-model="form.source" style="width:400px;"></el-input>
                </el-form-item>
                <el-form-item label="封面图">
                    <upload-img :modeldata="form" @changeurl="" style="margin-top:15px;"></upload-img>
                </el-form-item>
                <el-form-item label="内容">
                    <div class="editor-container">
                    <Uediter style="overflow:auto;height:650px;" :defaultMsg="defaultMsg" :config="config" ref="ue"></Uediter>
                    </div>
                </el-form-item>
                <el-form-item label="" style="margin-top:80px;">
                    <el-button type="primary" @click="addArticle">保存</el-button>
                </el-form-item>
    
            </el-form>
        </div>
    </div>
</template>
<script>
import Uediter from '@/components/ue.vue';
import Tinymce from "@/components/Tinymce";
import apiurl from '@/api/information'
import _api from '@/api/index'
export default {
    components: {Uediter},
    data(){
        return{
            userInfo:JSON.parse(window.localStorage.getItem("userInfo")),
            orglist:JSON.parse(localStorage.getItem("org")),
            defaultMsg: '',
            config: {
            initialFrameWidth: 1000,
            initialFrameHeight: 400
        },
            content: "",
            user:null,
            form:{
                title:"",
                classId:"",
                source:"",
                url:"",
                articleContent:"",
                author:"",
                orgId:null,
            },
            classlist:[],
            editorOption:{}
        }
    },
    created(){
        this.getClass()
        this.user=JSON.parse(localStorage.getItem("userInfo"))
        this.form.author=this.user.account
    },
    methods:{
        addArticle(){
            this.form.articleContent=this.$refs.ue.getUEContent()
            if(this.form.title==""){
                this.$message.error("请输入标题！")
                return
            }
            if(this.form.classId==""){
                this.$message.error("请选择分类！")
                return
            }
            if(this.form.source.length>10){
                this.$message.error("来源长度不能超过10个字符！")
                return
            }
           

            _api.post(apiurl.newsinsert,this.form).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("添加成功！");
                    this.form.title=""
                    this.form.classId=""
                    this.form.source=""
                    this.form.url=""
                    this.form.orgId=null
                    this.form.articleContent=""
                    this.$refs.ue.setUEContent('');
                   // Object.keys(this.form).forEach(key=>(this.form[key]=""))
                    
                }
            })
        },
        getClass(){
            _api.get(apiurl.list,{}).then(res=>{
                //console.log(res)
                this.classlist=res.data
            })
        },
        onEditorBlur(){//失去焦点事件
        },
        onEditorFocus(){//获得焦点事件
        },
        onEditorChange(){//内容改变事件
        }
    }
}
</script>
<style >
.el-form-item__content{ height: auto!important; line-height: 100%!important;}
#myeditor{ height: 550px!important;}
</style>
<style scoped>

.quillEditor{
        height: 300px;
    }
 .editor-container /deep/ .el-form-item__content{ height: auto!important; line-height: 100%!important;}
</style>